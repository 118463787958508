import React, { FunctionComponent, useCallback, useState, useEffect, useRef } from "react";
import styles from "./TopBarRemotoWeb.module.css";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const TopBarRemotoWeb: FunctionComponent = () => {


  const location = useLocation();
  console.log('Current path:', location.pathname);

  const navigate = useNavigate();
  const [burgerOpen, set_burgerOpen] = useState(false);
  const onLogoButtonClick = useCallback(() => {
    
    navigate('/');
  }, []);

  const onMenuButton2Click = useCallback(() => {
    
    navigate('/playback', { replace: true });
  }, []);

  const onMenuButton3Click = useCallback(() => {
    navigate('/download', { replace: true });
  }, []);


  const handleContact = useCallback(() => {
    navigate('/contact', { replace: true });
    
  }, []);
  const handleSupport = useCallback(() => {
    navigate('/help-center', { replace: true });
    
  }, []); //do we need to consider env here?

  const handleLegal = useCallback(() => {
    navigate('/resources', { replace: true });
    
  }, []);

  const onMenuButton6Click = useCallback(() => {
    navigate('/demo',   { replace: true });
  }, []);

  const onCareersButtonClick = useCallback(() => {
    navigate('/careers', { replace: true });
  }, []);
  const onPricingButtonClick = useCallback(() => {
    navigate('/store' , { replace: true });
  }, []);

  const onBurgerMenuClick = () => {

    set_burgerOpen(!burgerOpen)
  };
  const onBurgerMenuClose = () => {

    if (burgerOpen){
    set_burgerOpen(false)
    }

  };

  const menuRef = useRef(null); // To reference the menu DOM element

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (menuRef.current && !(menuRef.current as HTMLElement).contains(event.target)) {
        set_burgerOpen(false); // Close the menu if the click is outside
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.topBar} onClick={onBurgerMenuClose}>
      <div className={styles.topBarButtonsFrame}>
        <a href="/" className={styles.logoButton}>
          <img
            className={styles.remotosystemlogoIcon}
            alt=""
            src="/icons/logos/remotosystemlogo.svg"
          />
        </a>
        <div className={styles.rightLinks}>
          <a href="/" className={styles.menuButton1}>
            <div className={styles.div}>Home</div>
          </a>
          <a href="/playback" className={`${(location.pathname == '/playback') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Products</div>
          </a>
          <a href="/store" className={`${(location.pathname == '/store') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Buy Now</div>
          </a>
          <a href="/download" className={`${(location.pathname == '/download') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Download</div>
          </a>
          {/* Uncomment the following lines if needed */}
          <a href="/help-center" className={`${(location.pathname == '/help-center') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Help Center</div>
          </a> 
          {/* <a href="/resources" className={`${(location.pathname == '/resources') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Resources</div>
          </a> */}
          {/* <a href="/careers" className={`${(location.pathname == '/careers') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Careers</div>
          </a> */}
          <a href="/contact" className={`${(location.pathname == '/contact') ? styles.menuButton2_selected : styles.menuButton2}`}>
            <div className={styles.div1}>Contact Us</div>
          </a>
          <button className={styles.icBurguermenu} onClick={onBurgerMenuClick}>
            <div className={styles.line6StrokeParent}>
              <img
                className={styles.line6Stroke}
                alt=""
                src="/icons/components/line-6-stroke.svg"
              />
              <img
                className={styles.line6Stroke}
                alt=""
                src="/icons/components/line-6-stroke.svg"
              />
              <img
                className={styles.line6Stroke}
                alt=""
                src="/icons/components/line-6-stroke.svg"
              />
            </div>
          </button>
        </div>
      </div>
      {burgerOpen && (
        <div ref={menuRef} className={styles.burgerParent}>
          <a href="/playback" className={`${(location.pathname == "/playback") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Products
          </a>
          <a href="/store" className={`${(location.pathname == "/store") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Buy Now
          </a>
          <a href="/download" className={`${(location.pathname == "/download") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Download
          </a>
          {/* Uncomment the following lines if needed */}
          <a href="/help-center" className={`${(location.pathname == "/help-center") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Help Center
          </a>
          {/* <a href="/resources" className={`${(location.pathname == "/resources") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Resources
          </a> */}
          {/* <a href="/careers" className={`${(location.pathname == "/careers") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Careers
          </a> */}
          <a href="/contact" className={`${(location.pathname == "/contact") ? styles.burgerButton_selected : styles.burgerButton}`}>
            Contact Us
          </a>
        </div>
      )}
    </div>
  );
  
};

export default TopBarRemotoWeb;
