import { useEffect, useState, useRef} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './ContactForms.module.css'
import { useNavigate } from 'react-router-dom';
import Dropdown from './dropdown';
const SupportContact = () => {
    const [didSubmit, setDidSubmit] = useState(false)
    const [hoveredId, setHoveredId] = useState(null);
    const hoverTimeout = useRef(null);
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        firstName: Yup.string(),
        lastName: Yup.string(),
        help: Yup.string().required('Required'),
        phone: Yup.string(),
        jobTitle: Yup.array(),
        productType: Yup.string(),
        appVersion: Yup.string(),
        osVersion: Yup.string(),
        subject: Yup.string().required('Required'),
        // Add other fields as needed
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            help: '',
            phone: '',
            jobTitle: [],
            productType: '',
            appVersion: '',
            osVersion: '',
            subject: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const success = await sendFormDataToZapier(values);
            if (success) {
                setDidSubmit(true);
                // Handle successful form submission (e.g., clear form, show success message)
            } else {
                console.log("error submitting")
                // Handle unsuccessful form submission (e.g., show error message)
            }
        },
    });
    const navigate = useNavigate();
    const sendFormDataToZapier = async (formData) => {
        const zapierWebhookUrl = process.env.REACT_APP_ZAPIER_SUPPORT_HOOK; // Replace with your actual Zapier webhook URL

        try {
            const response = await fetch(zapierWebhookUrl, {
                method: 'POST',
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Handle success
                console.log('Form data sent successfully');
                return true; // Indicate success
            } else {
                // Handle HTTP errors
                console.error('Error sending form data: ', response.status);
                return false; // Indicate failure
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error when sending form data: ', error);
            return false; // Indicate failure
        }
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const currentValues = formik.values.jobTitle;
        if (checked && !currentValues.includes(value)) {
            formik.setFieldValue('jobTitle', [...currentValues, value]);
        } else if (!checked && currentValues.includes(value)) {
            formik.setFieldValue('jobTitle', currentValues.filter(item => item !== value));
        }
    };

    useEffect(() => {
        if (didSubmit) {
            setTimeout(() => {
                navigate('/help-center');
            }, 3000);
        }

    }, [didSubmit]);

    const handleMouseOver = (id) => {
        if (hoverTimeout.current) {
          clearTimeout(hoverTimeout.current);
          hoverTimeout.current = null;
        }
        setHoveredId(id);
      };
    
      const handleMouseOut = () => {
        hoverTimeout.current = setTimeout(() => {
          setHoveredId(null);
        }, 5000); // Delay in milliseconds
      };
      


    return (
        <div className={styles.formContainer}>
        {didSubmit && <h2> Thanks for your submission, we typically respond to all requests within 1 business day! </h2>}
        {!didSubmit && 
        <div>
        <form className={styles.mainForm} onSubmit={formik.handleSubmit}>
        <div className = {styles.formItemContainer}>
            <label htmlFor="email">Email *</label>
            <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                className = {styles.formFieldIn}
                value={formik.values.email}
                required
            />
            {formik.errors.email && formik.touched.email ? <div>{formik.errors.email}</div> : null}
            </div>
            <div className = {styles.formItemContainer}>

            <label htmlFor="firstName">First Name</label>
            <input
                id="firstName"
                name="firstName"
                type="text"
                className = {styles.formFieldIn}
                onChange={formik.handleChange}
                value={formik.values.firstName}
            />
            </div>
            <div className = {styles.formItemContainer}>

            <label htmlFor="lastName">Last Name</label>
            <input
                id="lastName"
                name="lastName"
                type="text"
                className = {styles.formFieldIn}
                onChange={formik.handleChange}
                value={formik.values.lastName}
            />
            </div>
            <div className = {styles.formItemContainer}>

              <label htmlFor="phone">Phone</label>
            <input
                id="phone"
                name="phone"
                type="text"
                className = {styles.formFieldIn}
                onChange={formik.handleChange}
                value={formik.values.phone}
            />
            </div>
            <br/>
            <div className = {styles.formItemContainer}>

            <label htmlFor="productType">Product Type</label>
            {/* <select
                id="productType"
                name="productType"
                className = {styles.formFieldOut}
                onChange={formik.handleChange}
                value={formik.values.productType}
            >
                <option value="">Product</option>
                <option value="Free">Free Session</option>
                <option value="HD">One Time HD</option>
                <option value="UHD">One Time UHD</option>
                <option value="BYO">One Time BYO</option>

                <option value="Pro">Pro</option>
                <option value="PlusTime">Plus Time</option>
                <option value="Plus Guests">Plus Guests</option>
                <option value="Ultra Time">Ultra Time</option>
                <option value="Ultra Guests">Ultra Guests</option>
                <option value="Ultra Both">Ultra Both </option>





                Add other options here 
            </select>*/}
            <Dropdown
                        options={['Free Session', 'One Time HD', 'One Time UHD', 'Pro', 'Plus Time', 'Plus Guests', 'Peak Time', 'Peak Both']}
                        value={formik.values.productType}
                        onChange={formik.handleChange}
                    />
            </div>
            <div className = {styles.formItemContainer}>

            <div className={styles.hoverContainer}>

   

                <div className = {styles.horizontalContainer}> 
                    <label className={styles.hoverText} htmlFor="appVersion">
                       Remoto Product version
                    </label> 
                    <h2 onMouseOver={() => handleMouseOver("appVersion")} 
                onMouseOut={handleMouseOut} className = {styles.qCircle}> {'    ?     '} </h2> 
                </div>
                {/* <select
                    id="appVersion"
                    name="appVersion"
                    className = {styles.formFieldOut}
                    onChange={formik.handleChange}
                    value={formik.values.appVersion}
                >
                    <option value="">Remoto Product</option>
                    <option value="RPv1.0.0">1.0.0</option>






                </select> */}
                <Dropdown
                        options={['1.0.0', '1.1.0', '1.1.1']}
                        value={formik.values.appVersion}
                        onChange={formik.handleChange}
                    />
                {<div className={(hoveredId == 'appVersion')?styles.hoverBox:styles.hoverBoxOff}> 
                    <a href={'https://docs.remotopro.io/en/how_to/How_to_Determine_Remoto_Playback_Software_Version'}> 
                    You'll find the product version number at the top right of the login screen.
                    </a>
                    </div>}
                    </div>
                    </div>
                    <div className={styles.formItemContainer}>

                    <label htmlFor="osVersion">Mac OS version</label>
                    {/* <select
                        id="osVersion"
                        name="osVersion"
                        
                        onChange={formik.handleChange}
                        value={formik.values.osVersion}
                        className={styles.formFieldOut} // Added className attribute
                    >
                        <option value="">(Mac OS Version)</option>
                        <option value="14">14 (Sonoma)</option>
                        <option value="13">13 (Ventura)</option>
                        <option value="12">12 (Monterey)</option>
                        <option value="11">11 (Big Sur)</option>
                        <option value="10.15">10.15 (Catalina)</option>
                    </select> */}
                    <Dropdown
                        options={['14 Sonoma', '13 Ventura', '12 Monterey', '11 Big Sur', '10.15 Catalina', 'Other']}
                        value={formik.values.hearAboutUs}
                        onChange={formik.handleChange}
                    />
                    </div>
                    <div className={styles.formItemContainer}>

                    <label htmlFor="jobTitle">Title and/or role (click all that apply)</label>
                    <div className={styles.gridContainer} id="jobTitle" onChange={formik.handleChange}>
                    <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="AV Management"
                                    name="jobTitle"
                                    value="AV Management"
                                    checked={formik.values.jobTitle.includes("AV Management")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="AV Management">AV Management</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="Design Consultant"
                                    name="jobTitle"
                                    value="Design Consultant"
                                    checked={formik.values.jobTitle.includes("Design Consultant")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="Design Consultant">Design Consultant</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="executiveManagement"
                                    name="jobTitle"
                                    value="Executive Management"
                                    checked={formik.values.jobTitle.includes("Executive Management")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                   // Added className attribute
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="executiveManagement">Executive Management</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="engineering"
                                    name="jobTitle"
                                    value="Engineering / R&D"
                                    checked={formik.values.jobTitle.includes("Engineering / R&D")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="engineering">Engineering / R&D</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="IT Management"
                                    name="jobTitle"
                                    value="IT Management"
                                    checked={formik.values.jobTitle.includes("IT Management")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="IT Management">IT Management</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="IT Staff"
                                    name="jobTitle"
                                    value="IT Staff"
                                    checked={formik.values.jobTitle.includes("IT Staff")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="IT Staff">IT Staff</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="Sales & Marketing Management"
                                    name="jobTitle"
                                    value="Sales & Marketing Management"
                                    checked={formik.values.jobTitle.includes("Sales & Marketing Management")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="Sales & Marketing Management">Sales & Marketing Management</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="Sound Engineer"
                                    name="jobTitle"
                                    value="Sound Engineer"
                                    checked={formik.values.jobTitle.includes("Sound Engineer")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="Sound Engineer">Sound Engineer</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="System Designer"
                                    name="jobTitle"
                                    value="System Designer"
                                    checked={formik.values.jobTitle.includes("System Designer")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="System Designer">System Designer</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="System Installer"
                                    name="jobTitle"
                                    value="System Installer"
                                    checked={formik.values.jobTitle.includes("System Installer")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="System Installer">System Installer</label>
                        </div>
                       
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="Technical Management"
                                    name="jobTitle"
                                    value="Technical Management"
                                    checked={formik.values.jobTitle.includes("Technical Management")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="Technical Management">Technical Management</label>
                        </div>
                        <div className={styles.horizontalContainer}>
                            <label className={styles.customCheckbox}>

                                <input
                                    type="checkbox"
                                    id="Other"
                                    name="jobTitle"
                                    value="Other"
                                    checked={formik.values.jobTitle.includes("Other")}
                                    onChange={() => handleCheckboxChange}
                                    style={{ width: 'auto' }}
                                />
                                <span className={styles.checkmark}></span>
                            </label>
                            <label className = {styles.checkboxText} htmlFor="Other">Other</label>
                        </div>

                        {/* Repeat for other options */}
                      

                        {/* ... additional checkboxes for each job title ... */}
                    </div>
                    </div>
                    <br/>
                    <div className={styles.formItemContainer}>


                    <label htmlFor="subject">Issue Summary *</label>
                    <input
                        id="subject"
                        name="subject"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.subject}
                        className={styles.formFieldIn} // Added className attribute
                    />
                    {formik.errors.subject && formik.touched.subject ? <div>{formik.errors.subject}</div> : null}
                    </div>
                    <div className={styles.formItemContainer}>
                    <label htmlFor="help">Support Request Details *</label>
                    <textarea
                        id="help"
                        name="help"
                        onChange={formik.handleChange}
                        value={formik.values.help}
                        style = {{height: '250px'}}
                        className={styles.formFieldIn} // Added className attribute
                    />
                    {formik.errors.help && formik.touched.help ? <div>{formik.errors.help}</div> : null}
                    </div>



                    <button className={styles.submitButton} type="submit" disabled={!(formik.isValid && formik.dirty)}>Submit</button>

                    </form>
                    </div>
                    }
                    </div>
                    );

}

export default SupportContact

