import { useEffect, useState, useContext } from 'react'
import styles from "./auth_flow.module.css";
import { StoreContext } from '../../context/StoreContext';
import { useNavigate } from 'react-router-dom';
import { set } from 'react-ga';
const StripeHandoffComponent = () => {

  const { env, getEnv, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken, userAction, setUserAction, setNotifyObject } = useContext(StoreContext);
  const navigate = useNavigate()
  const [stripeUrl, setStripeUrl] = useState('')
  const [portalUrl, setPortalUrl] = useState('')
  const [didCheckout, setDidCheckout] = useState(false)
  const nonSubscriptionIDList = [process.env.REACT_APP_FREE, process.env.REACT_APP_ONE_TIME_HD, process.env.REACT_APP_ONE_TIME_UHD]

  const blankObject = {
    message: '',
    code: '',
    type: ''
  }

  const fetch_active_subscriptions = async () => {
    try {
      const response = await fetch(`https://api.${env}remotopro.io/v1/subscriptions/current`, {
        method: 'GET',
        headers: {
          'ClientContext': 'WEB',
          'accept': 'application/json',
          'SolutionContext': 'WEB',
          'Authorization': `Bearer ${userToken}`
        }
      });


      const json = await response.json();

      console.log("#$959", json.stripeLicensedPriceId, json.scheduledStripeLicensedPriceId)
      if (json.stripeLicensedPriceId) {
        setCurrentPlan(json.scheduledStripeLicensedPriceId ? json.scheduledStripeLicensedPriceId : json.stripeLicensedPriceId);
        console.log("PLAN", currentPlan)
        return (json.scheduledStripeLicensedPriceId ? json.scheduledStripeLicensedPriceId : json.stripeLicensedPriceId)
      }
      else {
        setCurrentPlan('none')
        console.log("NO PLAN")
      }
    } catch (error) {
      setCurrentPlan('none')
      console.log('error', error)

      return ('error');
    } finally {
      return ('success')
    }
  };
  const getCustomerPortal = async (tkn) => {
    
    const url = `https://api.${env}remotopro.io/v1/subscriptions/portal`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'ClientContext': 'WEB',
        'accept': 'application/json',
        'SolutionContext': 'RP',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tkn}`
      }
    }
    try {
      const response = await fetch(url, requestOptions);

      const data = await response.json();
      console.log("$#$249", data)
      if (!response.ok) {




        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status == 200) {

        // Handle the response data as needed
        setPortalUrl(data.portalUrl)
      }
    } catch (error) {
      //setNotifyObject({ type: 'error', message: 'There was an error processing your request', code: '' })
      if (error.status == 404) {
        //setCurrentPlan('none')
        console.log('#(#(#((')
        //startPurchase(cart, userToken)

      }
    }
  }

  const changeSubscriptionPlan = async (plan, tkn) => {
    setNotifyObject(blankObject)

    const url = `https://api.${env}remotopro.io/v1/subscriptions/plan/change`;
    const payload = {
      'solution': 'RP',
      "newStripePriceId": plan
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'ClientContext': 'WEB',
        'accept': 'application/json',
        'SolutionContext': 'WEB',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tkn}`
      },
      body: JSON.stringify(payload)

    };

    try {
      const response = await fetch(url, requestOptions);
     

      console.log("$#$222", response)
      if (!response.ok && userToken) {
        const data = await response.json();
        if (response.status == 401) {
          setNotifyObject({ type: 'error', message: 'Your Session has expired please log in', code: '' })
          setUserAction('LOGIN')
        }
        else {
          setNotifyObject({ type: 'error', message: data.code.replace(/_/g, ' '), code: '' })

        }
       // throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.ok) {

        // Handle the response data as needed
        setNotifyObject({ type: 'success', message: 'your subscription has been upgraded', code: '' })
        setDidCheckout(true);
      }
    } catch (error) {
     
    }

  }



  const startPurchase = async (cart, tkn) => {
    setNotifyObject(blankObject)

    const url = `https://api.${env}remotopro.io/v1/payment/checkout/${cart}`;
    const payload = { 'solution': 'RP' };
    const requestOptions = {
      method: 'POST',
      headers: {
        'ClientContext': 'WEB',
        'accept': 'application/json',
        'SolutionContext': 'WEB',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tkn}`,
      },
      body: JSON.stringify(payload)
    }


    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        if (response.status == 401) {
          setNotifyObject({ type: 'error', message: 'Your Session has expired please log in', code: response.status })
          setUserAction('LOGIN')
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data)

      // Handle the response data as needed
      
      setStripeUrl(data.stripeUrl)
      setDidCheckout(true);
    } catch (error) {
      console.error('There was an error!', error);
      //setNotifyObject({ type: 'error', message: 'There was an error processing your request', code: '' })

    }
  };
  const openLinkInNewTab = (link) => {
    window.location.href = link
  };

  function checkAllowedAction(cart, currentPlan) {
    const allowedActions = {
      'price_1Obj6NGTwF4XBiKH4HxX4bAP': ['price_1OL5AaGTwF4XBiKHoMJyBYfl', 'price_1OL57LGTwF4XBiKH4wMcHGtr'],
      'price_1OL5AaGTwF4XBiKHoMJyBYfl': ['price_1OL57LGTwF4XBiKH4wMcHGtr'],
      'price_1OL57LGTwF4XBiKH4wMcHGtr': []
    }
    if (allowedActions[currentPlan].includes(cart)) {
      return true
    }
    else {
      return false
    }
  }
// Logs and redirects
  useEffect(() => {
    console.log("Current Plan:", currentPlan);
    console.log("User Token:", userToken);
    console.log("Cart:", cart);
    console.log("User Action:", userAction);
  
    if (!didCheckout && !userToken) {
      console.log("#3");
      setUserAction('CREATE_ACCOUNT');
    }
  }, [userToken, currentPlan, cart, userAction, didCheckout]);

  // Fetch current plan if needed
useEffect(() => {
  if (!didCheckout && userToken && userToken !== '' && !currentPlan) {
    fetch_active_subscriptions()
 

    console.log("#4");
  }
}, [userToken, currentPlan, didCheckout]);

// Handle navigation and customer portal access
useEffect(() => {
  if (!didCheckout && userToken && userToken !== '') {
    if (cart === '') {
      console.log("#5");
      navigate('/store');
    }
    getCustomerPortal(userToken);
  }
}, [userToken, cart, didCheckout]);

// Subscription and purchase management
useEffect(() => {
  if (!didCheckout && cart && currentPlan !== null && currentPlan !== '' && userToken) {
    if (!nonSubscriptionIDList.includes(cart) && currentPlan !== 'none') {
      changeSubscriptionPlan(cart, userToken);
      console.log("#6");
    } else {
      console.log("#7");
      startPurchase(cart, userToken);
    }
  }
}, [cart, currentPlan, userToken, didCheckout]);

  // useEffect(() => {
  //   console.log("Current", currentPlan)
  //   console.log("UserToken", userToken)
  //   console.log("Cart", cart)
  //   console.log("UserAction", userAction)

  //   if (!didCheckout) {
  //     console.log("#2")
  //     if (!userToken || userToken == '') {
  //       console.log("#3")
  //       setUserAction('CREATE_ACCOUNT')
  //     }
  //     else {
  //       if (cart == '') {
  //         console.log("#5")
  //         navigate('/store')
  //       }
  //       getCustomerPortal(userToken)
    
  //       if (!currentPlan) {

  //         let cPln = fetch_active_subscriptions()
  //         setCurrentPlan(cPln)
  //         console.log("#4", currentPlan)
  //       }
  //       //getCustomerPortal(userToken)

      
  //       if (cart) {
  //         if (!nonSubscriptionIDList.includes(cart) && currentPlan !== 'none') {
  //           //if(checkAllowedAction(cart, currentPlan)){
  //           changeSubscriptionPlan(cart, userToken)
  //           console.log("#6")
  //           //}
  //         }
  //         else {

  //           console.log("#7")
  //           startPurchase(cart, userToken)

  //         }
  //       }
  //     }
      

  //   }
  // }, [userToken, currentPlan])



  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginCard}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className={styles.logoImage} src={'Remoto PB 1 line.png'} />
        </div>
        <h2>
          {stripeUrl ? 'Please complete the transaction' : 'Processing purchase'}
        </h2>
        {stripeUrl &&
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', flexDirection: 'row' }}>
            <p> Having trouble? </p>
            <h2 onClick={openLinkInNewTab(stripeUrl)}> Click here to retry</h2></div>}
        {portalUrl && <h2 style = {{cursor: 'pointer'}} onClick={() => openLinkInNewTab(portalUrl)}> Click here to open customer portal</h2>}

      </div>
    </div>
  )
}

export default StripeHandoffComponent
