import React, { useState, useEffect } from 'react';
import OvenLiveKit from 'ovenlivekit'
import OvenPlayer from 'ovenplayer'
import styles from './network_test_component_styles.module.css'

function MediaTest() {
  const [audioPermission, setAudioPermission] = useState(0);
  const [videoPermission, setVideoPermission] = useState(0);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [networkStatus, setNetworkStatus] = useState(0);
  const [playerStatus, setPlayerStatus] = useState(0)
  const [browserInfo, setBrowserInfo] = useState([])
  const [qualityInfo, setQualityInfo] = useState([])
  const [playerLoading, setPlayerLoading] = useState(0)

  const [timestamp] = useState(new Date().getTime()); // Current timestamp
  const [offerInfo, setOfferInfo] = useState('')
  const [answerInfo, setAnswerInfo] = useState('')
  const [streamInfo, setStreamInfo] = useState('')
  const [streamAudioInfo, setStreamAudioInfo] = useState('')
  const [streamVideoInfo, setStreamVideoInfo] = useState('')

  function getStreamInfo() {
    fetch(`https://dev.ovenmediaengine.remotopro.io:8082/v1/vhosts/default/apps/app/streams/${timestamp}`, {
      method: 'GET', // Specifies the HTTP method
      headers: {
        'ClientContext': 'WEB',
        'Content-Type': 'application/json', // Sets the content type header
        'Authorization': 'Basic YWRtaW46dHZQR0BaKmt2NUpuWXM5Sg=='
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log("&&&", response)
          ; // Parses the JSON response
        return (response.json())
      })
      .then(data => {
        console.log("%$%$", data);
        setStreamInfo(data.response)
        getAVData(data.response)// Handles the data from the response

      })
      .catch(error => {
        console.error('Fetch error:', error); // Handles any errors
      });
  }

  function getAVData(dataObject) {

    if (!dataObject || !dataObject.input) {
      return null; // Return null if the input is invalid or doesn't have 'tracks'
    }
    console.log('333', dataObject.input)


    for (let track of dataObject.input.tracks) {
      console.log("track", track)
      if (track.type === "Audio") {
        setStreamAudioInfo(track); // Return the audio track object
        console.log("444", streamAudioInfo)
      }
      if (track.type === "Video") {
        setStreamVideoInfo(track); // Return the audio track object
      }
    }

    return null; // Return null if no audio track is found
  }
  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      getDevices();
      setAudioPermission(1);
      setTimeout(() => { setVideoPermission(1) }, 1000);
    } catch (error) {
      setAudioPermission(2);
      setVideoPermission(2);

      console.error("Permission denied or error occurred:", error);
    }
  };


  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audio = devices.filter(device => device.kind === 'audioinput');
      const video = devices.filter(device => device.kind === 'videoinput');
      setAudioDevices(audio);
      setVideoDevices(video);
      console.log("##5", audioDevices[0])
    } catch (error) {
      console.error("Error accessing media devices:", error);
      setAudioPermission(2);
      setVideoPermission(2);
    }


  };
  useEffect(() => {



 

    requestPermissions();
    getDevices();
    var config = {
      callbacks: {
        error: function (error) {
          console.log("#error", error)
        },
        connected: function (event) {
          console.log("#event", event)
          let answer = event.srcElement.currentLocalDescription.sdp

          setOfferInfo(answer)
          setAnswerInfo(event.srcElement.currentRemoteDescription.sdp)
        },
        connectionClosed: function (type, event) {
          console.log("#closed", type, event)
        },
        iceStateChange: function (state) {
          console.log("#iceState", state)
        }
      }
    }
  
    // Check for audio and video permissions
    const ovenLivekit_out = OvenLiveKit.create(config)

    // Test network connectivity
    // Get media stream from user device
    ovenLivekit_out.getUserMedia().then(() => {

      // Got device stream and start streaming to OvenMediaEngine
      ovenLivekit_out.startStreaming(`wss://dev.ovenmediaengine.remotopro.io:3001/app/${timestamp}?direction=send`);
      setTimeout(() => { setNetworkStatus(1) }, 6000);

      if (networkStatus == true) {

        const player = OvenPlayer.create('player_id', {
          sources: [
            {
              label: 'label_for_webrtc',
              // Set the type to 'webrtc'
              type: 'webrtc',
              // Set the file to WebRTC Signaling URL with OvenMediaEngine 
              file: `wss://dev.ovenmediaengine.remotopro.io:3002/app/${timestamp}`
            }
          ]
        })
        setPlayerLoading(1)
        player.showControls(false)
        player.on('bufferChanged', function (data) {
          console.log("##3", data)
          if (data.position != 0) {
            setPlayerStatus(1)
            setPlayerLoading(2)
            console.log("##4", playerStatus)

          }
          const tempval = player.getCurrentQuality()
          console.log("##6", tempval)

        })


        const browser = player.getBrowser();
        setBrowserInfo(browser)

        setTimeout(() => { player.play(), player.setVolume(0) }, 10)


      }


    }).catch((error) => {
      console.log("error", error)
      setNetworkStatus(2);
    });
    if (playerStatus == 1) {
      setTimeout(() => { getStreamInfo() }, 4000)
    }
    return (
      ovenLivekit_out.remove()

    )
  }, [networkStatus, playerStatus]);




  return (
    <div className={styles.componentMain}>
      <div style={{ width: '90vw', display: 'flex', flexDirection: 'column', paddingBottom: '6vh', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'left' }}>
        <h1>Media and Network Test</h1>
        <p className={styles.mainLabel}> Verify your settings to ensure the proper functionality of the app on your device</p>
      </div>
      <div className={styles.componentSub}>
      
        
          <div className={styles.cardMain}>
          <h2>Media Permissions</h2>
          <div className={styles.listContainer}>
            <p>Audio Permission:</p>
            <div className={styles.iconContainer}>

              {audioPermission == 0 && <img src="/icons/ToDo_Grey_REMT.png" />}
              {audioPermission == 1 && <img src="/icons/Success_Green_REMT.png" />}
              {audioPermission == 2 && <img src="/icons/Failure_Red_REMT.png" />}
            </div>

          </div>
          <div className={styles.listSubContainer}>
          <p className={styles.listLabel}> Connected Audio Sources: </p>
          <div className = {styles.listItemContainer}>

          {audioDevices.map((device) => {
            return (
              <p className={styles.devicesList}>{device.label}</p>
            )
          })}
          </div>
          </div>
          <div className={styles.listContainer}>
            
            <p>Video Permission:</p>
            
            <div className={styles.iconContainer}>

              {videoPermission == 0 && <img src="/icons/ToDo_Grey_REMT.png" />}
              {videoPermission == 1 && <img src="/icons/Success_Green_REMT.png" />}
              {videoPermission == 2 && <img src="/icons/Failure_Red_REMT.png" />}
            </div>
          </div>
          <div className={styles.listSubContainer}>

          <p className={styles.listLabel}> Connected Video Sources: </p>
          <div className = {styles.listItemContainer}>

          {videoDevices.map((device) => {
            return (
              <p className={styles.devicesList}>{device.label}</p>
            )
          })}
          </div>
        </div>
       </div>
       
       
        <div style = {{flexDirection: 'column', display: 'flex', gap: '20px', marginRight: '20px'}}>

        <div className={styles.cardMain}>
          <h2> Video Preview</h2>
          <div className={styles.playerContainer}>
            <div id="player_id">
            </div>

            {playerLoading == 0 && <div className={styles.playerLoading}> <h3 className = {styles.videoResultText}> Waiting for Server </h3> <img style = {{height: '25px'}} src="/icons/ToDo_Grey_REMT.png" /> </div>}
            {playerLoading == 1 && <div className={styles.playerLoading}> <h3 className = {styles.videoResultText}> Connecting to Server </h3> <img style = {{height: '25px'}} src="/icons/ToDo_Grey_REMT.png" /> </div>}
            {playerLoading == 2 && <div className={styles.playerLoading}> <h3 className = {styles.videoResultText}> Success </h3> <img style = {{height: '25px'}} src="/icons/Success_Green_REMT.png" /> </div>}
          </div>
        </div>
        <div className={styles.cardMain}>
          <h2>Streaming Diagnostics</h2>
          <div className={styles.listContainer}>
            <p>WebRTC Upstream Status:</p>
            <div className={styles.iconContainer}>

              {networkStatus == 0 && <img src="/icons/ToDo_Grey_REMT.png" />}
              {networkStatus == 1 && <img src="/icons/Success_Green_REMT.png" />}
              {networkStatus == 2 && <img src="/icons/Failure_Red_REMT.png" />}
            </div>

          </div>
          <div className={styles.listContainer}>
            <p>WebRTC Downstream Status:</p>
            <div className={styles.iconContainer}>
              {playerStatus == 0 && <img src="/icons/ToDo_Grey_REMT.png" />}
              {playerStatus == 1 && <img src="/icons/Success_Green_REMT.png" />}
              {playerStatus == 2 && <img src="/icons/Failure_Red_REMT.png" />}
            </div>
          </div>
          <div className={styles.listSubContainer}>


            <p className={styles.listLabel}> Browser Info: </p>
            <div className = {styles.listItemContainer}>

            {browserInfo.browser && <p className={styles.devicesList}>{browserInfo.browser}</p>}
            {browserInfo.browserVersion && <p className={styles.devicesList}>{browserInfo.browserVersion}</p>}
            {browserInfo.os && <p className={styles.devicesList}>{browserInfo.os}</p>}
            {browserInfo.osVersion && <p className={styles.devicesList}>{browserInfo.osVersion}</p>}
            {browserInfo.screen && <p className={styles.devicesList}>{browserInfo.screen}</p>}
          </div>
          </div>
          {streamVideoInfo && streamAudioInfo &&
            <div>
              <p className={styles.listLabel}> Source Url: </p>
              <div style={{ color: 'var(--swiss-coffee)' }}>
                <p className={styles.devicesList}> {streamInfo.input.sourceUrl}</p>
                <p> Audio:</p>
                <div className={styles.devicesList}>
                  <p> Bitrate: {streamAudioInfo.audio.bitrate}</p>
                  <p> Channels: {streamAudioInfo.audio.channel}</p>
                  <p> Codec: {streamAudioInfo.audio.codec}</p>
                  <p> Sample Rate: {streamAudioInfo.audio.samplerate}</p>

                </div>
                <p> Video:</p>
                <div className={styles.devicesList}>
                  <p> Bitrate: {streamVideoInfo.video.bitrate}</p>
                  <p> Codec: {streamVideoInfo.video.codec}</p>
                  <p> Sample Rate: {streamVideoInfo.video.samplerate}</p>
                  <p> Frame Rate: {streamVideoInfo.video.framerate}</p>
                  <p> Resolution: {streamVideoInfo.video.width} x {streamVideoInfo.video.height}</p>



                </div>


              </div>



            </div>
          }
        </div>
        </div>
        <div className = {styles.cardSpeedTest}> 
        <iframe 
          src={'https://speedtest.noncore.remotopro.io?Run'} 
          width="100%" 
          height="500px" 
          title="OpenSpeedTest"
          frameBorder={0}
    
        ></iframe>
        </div>
      </div>

    </div>
  );
}

export default MediaTest;

