import { useState, useEffect } from "react";
import styles from "./Store.module.css";
import React, { useContext } from 'react';
import { StoreContext } from "../../context/StoreContext";
import { useNavigate, useLocation } from 'react-router-dom';
import CardTemplate from "./card_component";
import { decode } from "punycode";


const StoreComponent = () => {
    const queryParams = new URLSearchParams(location.search);

    const [selectedType, setSelectedType] = useState('')

    const [dummyGetProducts, setDummyGetProducts] = useState({})
    const { env, getEnv, challengeId, setChallengeId, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken } = useContext(StoreContext);
    const [isTest, setIsTest] = useState(false)
    const [loading, setLoading] = useState(true);
    const [storeResponse_OTS, setStoreResponse_OTS] = useState([])
    const [storeResponse_SUB, setStoreResponse_SUB] = useState([])
    const [origin, setOrigin] = useState(queryParams.get('origin'))
    const [signature, setSignature] = useState(queryParams.get('signature'))
    const navigate = useNavigate();


    function goLogin() {
        navigate('/auth')
    }

    const getToken = async (origin, signature) => {

        const url = `https://api.${env}remotopro.io/v1/auth/login/passwordless`;
        function decodeUrlSafeBase64(base64) {
            return btoa(atob(decodeURIComponent(base64.replace(/_/g, '/').replace(/-/g, '+'))));
        }

        // Example usage

        let _origin = decodeUrlSafeBase64(origin)
        let _signature = decodeUrlSafeBase64(signature)

        console.log(_origin);


        const requestOptions = {
            method: 'POST',
            headers: {
                'ClientContext': 'WEB',
                'accept': 'application/json',
                'SolutionContext': 'WEB',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'origin': _origin,
                'signature': _signature
            })

        };

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                if (response.status == '403') {
                    alert('sesssion expired')
                }
                throw new Error(`HTTP error! status: ${response.status}`);

            }
            const data = await response.json();
            console.log(data)
            if (data.token) {
                setUserToken(data.token)
                return
            }
            if (data.challenge === 'LOGOUT_FROM_OTHER_DEVICES') {
                setChallengeId(data.sessionId)
            }


            // Handle the response data as needed
        } catch (error) {
            console.error('There was an error!', error);
        }

    };

    const login_challenge = async (session_id) => {
        const url = `https://api.${env}remotopro.io/v1/auth/logout/other-devices`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'ClientContext': 'WEB',
                'accept': 'application/json',
                'SolutionContext': 'WEB',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sessionId: session_id,
                solution: 'WEB'
            })
        };

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log("##LGCHALLENGE", data)
            if (data.token) {
                setUserToken(data.token)
                return ('success')
            }
            // Handle the response data as needed

        } catch (error) {
            console.error('There was an error!', error);
        }
    };


    const fetch_active_subscriptions = async () => {
        try {
            const response = await fetch(`https://api.${env}remotopro.io/v1/subscriptions/current`, {
                method: 'GET',
                headers: {
                    'ClientContext': 'WEB',
                    'accept': 'application/json',
                    'SolutionContext': 'RP',
                    'Authorization': `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json();
            console.log("#$959", json)
            if (json.scheduledStripeLicensedPriceId == cart || json.stripeLicensedPriceId == cart) {
                setCart('')
            }
            setCurrentPlan(json.scheduledStripeLicensedPriceId ? json.scheduledStripeLicensedPriceId : json.stripeLicensedPriceId);

        } catch (error) {
            console.log('error')
            return ('error');
        } finally {
            return ('success')
        }
    };
    const fetchStore_OTS = async () => {
        try {
            const response = await fetch(`https://api.${env}remotopro.io/v1/store/one-time-session-product/solution/RP`, {
                method: 'GET',
                headers: {
                    'ClientContext': 'WEB',
                    'accept': 'application/json',
                    'SolutionContext': 'WEB'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json();
            console.log(json)
            setStoreResponse_OTS(json);

        } catch (error) {
            console.log('error')
            return ('error');
        } finally {
            setLoading(false);
        }
    };
    const fetchStore_SUB = async () => {
        try {
            const response = await fetch(`https://api.${env}remotopro.io/v1/store/subscription-plan/solution/RP`, {
                method: 'GET',
                headers: {
                    'ClientContext': 'WEB',
                    'accept': 'application/json',
                    'SolutionContext': 'WEB'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json = await response.json();
            console.log("##44", json)
            setStoreResponse_SUB(json);

        } catch (error) {
            console.log('error')
            return ('error');
        } finally {
            setLoading(false);
        }
    };



    const dummyResponse = [

        {
            "id": "price_1OHmpeJS2eq6Ic9K2bXELQKd",
            "name": "One-time Session (Free) (RP)",
            "description": "This product gives the user ability to have a limited number of sessions for free.",
            "currency": "usd",
            "amount": 0,
            "quantity": 3,
            "free": true,
            "solution": "RP",
            "sessionProductAttributes": {
                "maxGuests": 10,
                "roomAvailableAfterStreamTerminatedInMinutes": 10,
                "streamingProviders": [
                    "DOLBY",
                    "AWS"
                ],
                "maxVideoCanvasOutputPixels": 10,
                "maxVideoOutputFrameRate": 10.2,
                "maxAudioChannels": 4,
                "chroma": "4:2:2",
                "consumeTime": 1,
                "isActive": true
            }
        },
        {
            "id": "price_1OHmVAJS2eq6Ic9K2YASVwn6",
            "name": "HD",
            "description": "This product gives the user ability to have a one-time session, without the need of a recurring payment commitment.",
            "currency": "usd",
            "amount": 90,
            "quantity": 1,
            "free": false,
            "solution": "RP",
            "sessionProductAttributes": {
                "maxGuests": 10,
                "roomAvailableAfterStreamTerminatedInMinutes": 10,
                "streamingProviders": [
                    "DOLBY",
                    "AWS"
                ],
                "maxVideoCanvasOutputPixels": 1080,
                "maxVideoOutputFrameRate": 30,
                "maxAudioChannels": 2,
                "chroma": "4:2:2",
                "consumeTime": 1,
                "isActive": true


            }
        }
    ]

    function transformResponse(object) {



        let obj_ = object.map((item) => {

            let inner_obj = {}
            inner_obj['name'] = item.name.split('(')[0]
            inner_obj['priceId'] = item.id
            inner_obj['details'] = item.description
            inner_obj['price'] = item.amount
            inner_obj['videoRes'] = item.sessionProductAttributes.maxVideoCanvasOutputPixels ? item.sessionProductAttributes.maxVideoCanvasOutputPixels : 1080
            inner_obj['fps'] = item.sessionProductAttributes.maxVideoOutputFrameRate
            inner_obj['audioChannels'] = item.sessionProductAttributes.maxAudioChannels
            inner_obj['chroma'] = item.sessionProductAttributes.chroma ? item.sessionProductAttributes.chroma : '4:2:0'
            inner_obj['label'] = item.name.split('(')[0]

            inner_obj['guestMax'] = item.sessionProductAttributes.maxGuests
            inner_obj['consumeTime'] = item.sessionProductAttributes.maxDurationInMinutes ? item.sessionProductAttributes.maxDurationInMinutes / 60 : 1
            inner_obj['isActive'] = item.sessionProductAttributes.isActive ? item.sessionProductAttributes : true
            return (inner_obj)



        })
        let products = [...obj_]
        console.log("#products:", products)

        return (
            products
        )
    }
    function transformResponse_SUB(object) {

        console.log("##SUB", object)

        let obj_ = object.map((item) => {
            let inner_obj = {}
            inner_obj['name'] = item.name
            inner_obj['priceId'] = item.id
            inner_obj['details'] = item.description
            inner_obj['price'] = item.amount
            inner_obj['videoRes'] = (item.attributes.RP.sessionProductAttributes.maxVideoCanvasOutputPixels / 1920 == 1080) ? 'FHD' : 'UHD'
            inner_obj['fps'] = item.attributes.RP.sessionProductAttributes.maxVideoOutputFrameRate ? item.attributes.RP.sessionProductAttributes.maxVideoOutputFrameRate : 30
            inner_obj['audioChannels'] = item.attributes.RP.sessionProductAttributes.maxAudioChannels ? item.attributes.RP.sessionProductAttributes.maxAudioChannels : 16
            inner_obj['chroma'] = item.attributes.RP.sessionProductAttributes.chroma ? item.attributes.RP.sessionProductAttributes.chroma : '4:2:2'
            inner_obj['label'] = item.name
            inner_obj['guestMax'] = item.attributes.RP.sessionProductAttributes.maxGuests
            inner_obj['consumeTime'] = item.attributes.RP.sessionProductAttributes.maxDurationInMinutes ? item.attributes.RP.sessionProductAttributes.maxDurationInMinutes / 60 : 1
            inner_obj['isActive'] = item.attributes.RP.sessionProductAttributes.isActive ? item.attributes.RP.sessionProductAttributes.isActive : true
            inner_obj['type'] = 'subscription' 
            return (inner_obj)



        })
        let products = [...obj_]
        console.log("#products:", products)

        return (
            products
        )
    }




    function combineProducts(products, template) {
        let out_obj = {}
        let out_array = [...products]
        template.map((product) => {
            out_array.push(product)
        })
        out_obj['products'] = out_array
        return (out_obj)
    }
    const templateGetProducts = {
        products: [
            {
                name: "HD",
                details: 'Perfect for quick reviews or one off sessions',
                priceId: "OT1",
                label: '',
                type: 'one_time',
                price: 29,
                videoRes: "HD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 5,
                consumeTime: 3,
                isActive: true
            },
            {
                name: "UHD",
                details: 'Perfect for a one time high quality session',
                priceId: "OT2",
                label: '',
                type: 'one_time',
                price: 59,
                videoRes: "UHD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:2",
                guestMax: 5,
                consumeTime: 3,
                isActive: false
            },
            {
                name: "CUSTOM Session",
                details: 'Customize session features to optimize your workflow or budget',
                priceId: "BYO",
                label: '',
                type: 'one_time',
                price: "Price based on configuration",
                videoRes: "Up to UHD",
                fps: "Up to 60",
                audioChannels: "Up To 16",
                chroma: "Up to 4:2:2",
                guestMax: "Up to 50",
                consumeTime: "Up to 10",
                isActive: false

            },
            {
                name: "Pro Plan",
                details: `20 total session hours \n Up to 5 guests in any session`,
                priceId: "PP1",
                label: '',
                type: 'subscription',
                price: 199,
                videoRes: "HD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 5,
                consumeTime: 3,
                isActive: true
            },
            {
                name: "Plus Plan",
                details: 'Need more time or more guests?',
                priceId: "PP2G",
                type: 'subscription',
                price: 299,
                label: 'More Guests',
                videoRes: "HD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 10,
                consumeTime: 5,
                isActive: true
            },
            {
                name: "Plus Plan",
                details: 'Need more time or more guests?',
                priceId: "PP2T",
                type: 'subscription',
                label: 'More Time',
                price: 299,
                videoRes: "HD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 5,
                consumeTime: 10,
                isActive: true
            },
            {
                name: "Ultra Plan",
                details: 'Get even more time or even more guests, or both!',
                priceId: "PP3G",
                type: 'subscription',
                price: 399,
                videoRes: "HD",
                label: 'More Guests',
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 15,
                consumeTime: 20,
                isActive: true
            },
            {
                name: "Ultra Plan",
                details: 'Get even more time or even more guests, or both!',
                priceId: "PP3T",
                price: 399,
                label: 'More Time',
                videoRes: "HD",
                type: 'subscription',
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 10,
                consumeTime: 30,
                isActive: true
            },
            {
                name: "Ultra Plan",
                details: 'Get even more time or even more guests, or both!',
                priceId: "PP3B",
                label: 'Both',
                price: 449,
                type: 'subscription',
                videoRes: "HD",
                fps: 30,
                audioChannels: 16,
                chroma: "4:2:0",
                guestMax: 15,
                consumeTime: 30,
                isActive: true
            },
            {
                name: "Null",
                details: '',
                priceId: "NULL",
                label: '',
                price: 0,
                type: 'null',
                videoRes: "",
                fps: 0,
                audioChannels: 0,
                chroma: "",
                guestMax: 0,
                consumeTime: 0,
                isActive: true
            },
            {
                name: "OT",
                details: '',
                priceId: "NULL",
                label: '',
                price: 0,
                type: 'null',
                videoRes: "",
                fps: 0,
                audioChannels: 0,
                chroma: "",
                guestMax: 0,
                consumeTime: 0,
                isActive: true
            },
            {
                name: "PP",
                details: '',
                priceId: "NULL",
                label: '',
                price: 0,
                type: 'null',
                videoRes: "",
                fps: 0,
                audioChannels: 0,
                chroma: "",
                guestMax: 0,
                consumeTime: 0,
                isActive: true
            }

        ]
    }

    const getProductById = (products, priceId) => {
        console.log("##@", priceId)
        let getVal = products.find(product => product.priceId === priceId);
        if (getVal) { return (getVal) }
        else return (products.priceId === 'NULL')
    }


    const PromoContainer = () => {
        return (
            <div className={styles.promoContainer}>
                {/* <img src={'./launch_special1k.png'} className={styles.promoImage} /> */}
                <div className = {styles.promoTextBanner}> ★ <p style = {{fontSize: '16px'}}> Launch Deal</p> ★</div>
                <div className={styles.promoText}> 
                
                 Free upgrade to<p className = {styles.promoTextHighlight}>16 discrete audio channels </p> on all sessions and plans. </div>
            </div>

        )
    }





    const SubscriptionsContainer = () => {
        return (
            <div className={styles.promoCardParent}>
                <PromoContainer />
                <div className={styles.cardContainer}>

                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_PRO)]}> </CardTemplate>


                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_P_TIME), getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_P_GUESTS)]}> </CardTemplate>


                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_U_TIME), getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_U_GUESTS), getProductById(dummyGetProducts.products, process.env.REACT_APP_SUBSCRIPTION_U_BOTH)]}> </CardTemplate>
                </div>
            </div>
        )
    }

    const OneTimeContainer = () => {
        return (
            <div className={styles.promoCardParent}>
                <PromoContainer />
                <div className={styles.cardContainer}>

                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_ONE_TIME_HD)]}> </CardTemplate>
                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_ONE_TIME_UHD)]}> </CardTemplate>
                    <CardTemplate params={[getProductById(dummyGetProducts.products, 'BYO')]}> </CardTemplate>

                </div>

            </div>
        )
    }

    const FreeContainer = () => {
        return (
            <div className={styles.promoCardParent}>
                <div style={{ height: '20px' }}> </div>
                <div className={styles.cardContainer}>

                    <CardTemplate params={[getProductById(dummyGetProducts.products, process.env.REACT_APP_FREE)]}> </CardTemplate>


                </div>
            </div>
        )
    }

    const ExampleContainer = () => {
        return (
            <div> </div>

        )
    }


    useEffect(() => {

        if (env != 'unset') {
            if (origin && signature) {

                if (!userToken && !challengeId) {
                    getToken(origin, signature)
                }
                if (challengeId && !userToken) {
                    login_challenge(challengeId)
                }

            }
            if (userToken) {
                fetch_active_subscriptions()

            }

            if (loading == true && (storeResponse_OTS.length === 0 || storeResponse_SUB.length === 0)) { fetchStore_OTS().then(fetchStore_SUB())
            }
          
            
            console.log("##13", storeResponse_OTS)
            let formed_OTS = transformResponse(storeResponse_OTS)
            let formed_SUB = transformResponse_SUB(storeResponse_SUB)
            let formed = [...formed_OTS, ...formed_SUB]
            console.log("##14", formed)
            setDummyGetProducts(combineProducts(formed, templateGetProducts.products))
            console.log("##15", dummyGetProducts)
        }
        else {
            getEnv()
        }
       

    }, [env, storeResponse_SUB, storeResponse_OTS, userToken, challengeId])


    return (
        <div>
            {!loading && <div className={styles.mainComponent}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <h1 className = {styles.headerText}> Remoto Playback is available both per session and per month.  </h1>
                 
                 
                </div>
                {<div className={styles.mainSelector}>

                    <div className={styles.mainSelectorWrapper}>
                        {(selectedType !== 'free') && <div className={(selectedType === 'one_time' || selectedType === '') ? styles.mainSelectorSliderOT : styles.mainSelectorSliderSB}> </div>}
                        {(selectedType === 'free') && <div className={styles.mainSelectorSliderFR}> </div>}

                        {(selectedType === 'free') && <div className={(selectedType === 'free') ? styles.mainSelectorFree : styles.mainSelectorOff}> Free Test Drive </div>}

                        <div className={(selectedType === 'one_time' || selectedType === '') ? styles.mainSelectorOn : styles.mainSelectorOff} onClick={() => setSelectedType('one_time')}> One-Time Sessions</div>
                        <div className={(selectedType === 'subscriptions' ) ? styles.mainSelectorOn : styles.mainSelectorOff} onClick={() => setSelectedType('subscriptions')}> Subscriptions </div>


                    </div>
                </div>}
            
                {(selectedType == 'free' && (storeResponse_OTS && storeResponse_SUB)) && <FreeContainer />}
                {(selectedType == 'subscriptions' && (storeResponse_OTS && storeResponse_SUB)) && <SubscriptionsContainer />}
                {((selectedType == 'one_time' || selectedType == '') && (storeResponse_OTS && storeResponse_SUB)) && <OneTimeContainer />}
                {/* {(selectedType == '') &&
                    <div className={styles.funnelComponent} >

                        <div className={styles.cardContainer}>

                            <div className={styles.funnelCard}>
                                <h1 className={styles.funnelHeader}> Per Session </h1>

                                <div className={styles.funnelContainer}>

                                    <div className={styles.funnelPrice}>
                                        <p className={styles.greyText}>Starting at</p>
                                        <p className={styles.smallPrice}> $30 </p>
                                        <p className={styles.greyText}>per session</p>
                                    </div>
                                    <button className={styles.buttonFunnel} onClick={() => setSelectedType("one_time")}> Show Me More </button>
                                </div>
                            </div>
                            <div className={styles.funnelCard}>
                                <h1 className={styles.funnelHeader}> Subscription</h1>

                                <div className={styles.funnelContainer}>
                                    <div className={styles.funnelPrice}>
                                        <p className={styles.greyText}>Starting at</p>
                                        <p className={styles.smallPrice}> $199 </p>
                                        <p className={styles.greyText}>per month</p>
                                    </div>
                                    <button className={styles.buttonFunnel} onClick={() => setSelectedType("subscriptions")} > Show Me More </button>
                                </div>
                            </div>

                        </div>
                    </div>} */}
                {(selectedType != 'free') && <div className={styles.contactLink}>
                
                New to Remoto Playback? Take a <a onClick={() => setSelectedType('free')}>Free Test Drive</a> or  <a href = "/contact"> contact us</a> for custom configurations.
                </div>}



            </div >}
           
            </div>
    )
}

export default StoreComponent