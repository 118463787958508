import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import styles from "./auth_flow.module.css";


import { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import { useNavigate } from "react-router-dom";

const LoginChallengeComponent = () => {
    const { env, cart, setCart, currentPlan, setCurrentPlan, userToken, setUserToken, userAction, setUserAction, challengeId, setChallengeId, setNotifyObject } = useContext(StoreContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
      };
      const handlePasswordChange = (e) => {
        setPassword(e.target.value);
      };
      const blankObject = {
        message: '',
        code: '',
        type: ''
      }
      const login_challenge = async (session_id) => {
        setNotifyObject(blankObject);
        const url = `https://api.${env}remotopro.io/v1/auth/logout/other-devices`;
        const requestOptions = {
          method: 'POST',
          headers: {
            'ClientContext': 'WEB',
            'accept': 'application/json',
            'SolutionContext': 'WEB',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            sessionId: session_id, 
            solution: 'WEB'
          })
        };
      
        try {
          const response = await fetch(url, requestOptions);
          if (!response.ok) {
            
              setNotifyObject({type: 'error', message: data.code.replace(/_/g, ' '), code: response.status})
            
          }
          const data = await response.json();
          console.log(data)
          if (data.token){
          setNotifyObject({type: 'success', message: 'login successful', code: ''})

          setUserToken(data.token)
          setUserAction('STRIPE_HANDOFF')

          }
          // Handle the response data as needed
          console.log(data);
        } catch (error) {
          console.error('There was an error!', error);
        }
      };


    return(
        <div className = {styles.mainContainer}>
        
            <div className = {styles.loginCard}> 
            <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className={styles.logoImage} src = {'Remoto PB 1 line.png'} /> 
        </div>
        <div style = {{width: '100%'}}>
                <p className = {styles.inputLabel} style = {{textAlign: 'center', marginBottom: '20px'}}> It appears that you are currently signed in from another device.
                Do you want to sign out from that device and continue here?</p>

               
                <div className = {styles.hzLabelContainer} style = {{ width: 'stretch', justifyContent: 'center', gap: '10px'}}> 

                
                
                <button className = {styles.cancelButton} style = {{width: '170px'}} onClick = {()=> setUserAction('LOGIN')}> No</button>

                <button className={styles.actionButton} style = {{width: '170px'}} onClick={() => login_challenge(challengeId)}> Yes</button>
               
              
                </div>
            </div>
            
            </div>
            </div> 
    )
}
export default LoginChallengeComponent
