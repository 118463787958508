import React, { useEffect, useState, useRef } from 'react';
import './VideoCarousel.css';

const videos = [
  {
    id: 1,
    title: "Welcome to RP!",
    url: "https://www.youtube.com/embed/esu-Ldx6Nu4?si=E-rA9_UuY-itBWC_",
    description: "Welcome to Remoto Playback!"
  },
  {
    id: 2,
    title: "How to Set Up Remoto Playback's Free Test Drive",
    url: "https://www.youtube.com/embed/2_QapVmGAIQ?si=vGNOr1jK3o2K_Tq_",
    description: "How to Set Up Remoto Playback's Free Test Drive"
  }
];

const VideoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false); // Track if the video is playing
  const playerRefs = useRef([]); // To store YouTube iframe API player instances
  const videoLength = videos.length;
  
  // Function to go to the previous video
  const goToPrev = () => {
    const newIndex = currentIndex === 0 ? videoLength - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  // Function to go to the next video
  const goToNext = () => {
    const newIndex = currentIndex === videoLength - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Set up YouTube Iframe API and player instances
  useEffect(() => {
    window.onYouTubeIframeAPIReady = () => {
      videos.forEach((video, index) => {
        playerRefs.current[index] = new window.YT.Player(`player-${index}`, {
          events: {
            onStateChange: onPlayerStateChange,
          },
        });
      });
    };

    // Load the YouTube Iframe API script
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return () => {
      // Cleanup if needed
      playerRefs.current.forEach((player) => player?.destroy());
    };
  }, []);

  // Track player state changes
  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setIsPlaying(true); // Video is playing
    } else if (
      event.data === window.YT.PlayerState.PAUSED ||
      event.data === window.YT.PlayerState.ENDED
    ) {
      setIsPlaying(false); // Video is paused or ended
    }
  };

  // Automatically go to the next video every 15 seconds (unless a video is playing)
  // useEffect(() => {
  //   let interval;
  //   if (!isPlaying) {
  //     interval = setInterval(() => {
  //       goToNext();
  //     }, 15000); // Auto-scroll every 15 seconds
  //   }

  //   return () => clearInterval(interval);
  // }, [currentIndex, isPlaying]);

  return (
    <div className="carousel-container"// Start playing
>
      <div className="video-wrapper">
        {videos.map((video, index) => (
          <iframe
            key={video.id}
            id={`player-${index}`} // Unique ID for each YouTube player
            src={`${video.url}?enablejsapi=1`} // Enable JS API to control the video
            style={{ display: index === currentIndex ? 'block' : 'none' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={video.title}
          ></iframe>
        ))}
      </div>
      <div className="arrows">
        <button onClick={goToPrev}>&lt;</button>
        <button onClick={goToNext}>&gt;</button>
      </div>
      <div className="dots">
        {videos.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => setCurrentIndex(index)} // Allow manual navigation using dots
          ></span>
        ))}
      </div>
    </div>
  );
};

export default VideoCarousel;
