import styles from "./HelpComponent.module.css";
import React from "react";
import { useEffect, useState, useRef } from "react";
import SearchBar from "./HelpSearchComponent";
import DanteChatBox from "./DanteAiComponent";
import SupportContact from "../ContactForms/support_contact";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoCarousel from "./VideoCarousel";

const HelpComponent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const showForm = false;
  const botOpen =  false;


  const navigateToExternalUrl = (url) => {
    window.open(url, '_blank');
  };
  const defaultCopy = [
    {
      tag: "Getting Started",
      text: "Quick start guides on topics including account creation and session setup",
      link: 'https://support.remotopro.io/en_US/quickstart-guides/welcome-to-remoto-playback'
    },
    {
      tag: "FAQ",
      text: "Start here for answers to common questions",
      link: 'https://support.remotopro.io/faqs/remoto-playback-faq'
    },
    {
      tag: "Documentation",
      text: "User guides and release notes",
      link: "https://support.remotopro.io/user-guide/remoto-playback-release-documentation"
    },

  ];


  const grid = defaultCopy.map((listVal) => {
    return (
      <div className={styles.gridCard}>
        <div className={styles.gridCardChildContainer} onClick={() => { navigateToExternalUrl(listVal.link) }}>
          <h2 className={styles.cardHeader}>{listVal.tag}</h2>
          <div className={styles.cardTextRow}>
            <p className={styles.cardText}>{listVal.text}</p>
            <img
              className={styles.doubleArrow}
              src="./icons/featuresPlaybackPage/DoubleArrow.svg"
            />
          </div>
        </div>
      </div>
    );
  });





  


  function getHeadlineText(botOpen, showForm) {

    if (botOpen) {
      return 'Chat with Remi our customer support AI!'
    }
    if (showForm) {
      return 'Submit a Support Request'
    }



  }


  return (
    <div className={styles.helpComponent}>
      <h1 className={styles.headlineText}>How can we help?</h1>
      <div className={styles.contentContainer}>




        {!botOpen && <div>

          {showForm && <div id={'supportForm'}> <SupportContact />
          </div>}
          {!showForm && <div className={styles.mainHelpContentContainer}> 
           {/*<div className={styles.searchContainer}>
           <SearchBar/>


    
          </div> */}
          <VideoCarousel />
          {/* <div className={styles.videoContainer}>
            <iframe
              className={styles.video}
              src="https://www.youtube.com/embed/LPF8bVzCEVw?si=3-wIhvWeJDr03o_l" 
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div> */}
          <div className={styles.gridContainer}>{grid}</div>
           </div>}

          <div className={styles.hz}>

            <div className={styles.supportButton} onClick={() => { navigateToExternalUrl('https://support.remotopro.io/') }}>
             <a style = {{textDecoration: 'none'}} > Knowledge Base Home </a>
            </div> 

            {/* <div className={styles.supportButton} onClick={() => {navigate('/support-bot')}}>
             Ask Remi
            </div> */}


            <div className={styles.supportButton} onClick={() => navigate('/support-form')}>
              {showForm ? 'Back to Help Center' : 'Submit A Support Request'}
            </div>

          </div>

        </div>
        }
        {(botOpen) && <div className={styles.closeBotButton} onClick={() => navigate('/support-bot')}> Back to Help Center </div>}
      </div>

    </div>
  );
};

export default HelpComponent;
