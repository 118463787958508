import {useEffect, useState, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {StoreContext} from "../../context/StoreContext";
import styles from "./notifyModal.module.css";

const NotifyModal = () => {

const {notifyObject, setNotifyObject} = useContext(StoreContext);


let styleVal = `styles.${notifyObject.type}`
const blankObject = {
    message: '',
    code: '',
    type: ''
}
useEffect(()=>{
    console.log("NOTIFY OBJECT:", notifyObject) 
    setTimeout(() => {setNotifyObject(blankObject)}, 10000)    
}, [setNotifyObject])


return (
    <div className={styles.modalContainer}>
        {notifyObject.type == 'error' && <div className={styles.notify}>
            <h3 className={styles.errorCode}>{notifyObject.code}</h3>
            <p className={styles.notifyText}>{notifyObject.message}</p>
            <button className={styles.errorCloseButton} onClick={() => setNotifyObject(blankObject)}>X</button>
        </div>}
        {notifyObject.type == 'success' && <div className={styles.notify}>
            <h3 className={styles.successCode}>{notifyObject.code}</h3>
            <p className={styles.notifyText}>{notifyObject.message}</p>
            <button className={styles.successCloseButton} onClick={() => setNotifyObject(blankObject)}>X</button>
        </div>}
        {notifyObject.type == 'alert' && <div className={styles.alert}>
            <h3 className={styles.alertCode}>{notifyObject.code}</h3>
            <p className={styles.notifyText}>{notifyObject.message}</p>
            <button className={styles.alertCloseButton} onClick={() => setNotifyObject(blankObject)}>X</button>
        </div>}

    </div>
)

}

export default NotifyModal